
if (document.querySelector('#menuContent')) {
    const menuItem = $('#menuContent a'),
        container = $('#content'),
        menuContent = document.getElementById('menuContent');


    // Menü erstellen
    (function () {
        let menuOutput = '';
        for (var i = 0; i < navigationObject.length; i++) {
            menuOutput += `
        <div class="menuItem">
            <a data-target="${navigationObject[i].main.toLocaleLowerCase()}">${navigationObject[i].main}</a>`;

            if (navigationObject[i].sub) {
                menuOutput += '<div class="submenu">'
                for (var j = 0; j < navigationObject[i].sub.length; j++) {
                    menuOutput += `<a class="submenuItem" data-target="${navigationObject[i].sub[j].toLowerCase().replace(/ /g, '')}">${navigationObject[i].sub[j]}</a>`;
                }
                menuOutput += `</div>`

            }
            menuOutput += `</div>`
        }
        menuOutput += `<div class="menuItem">
    <a href="backend.html" id="login" href="?zugangsdaten">🔒 Zugangsdaten</a></div>`

        menuContent.innerHTML = menuOutput;
    })()

    // Document Ready
    $(function () {

        $.get("includes/allgemeines.html", function (data) {
            container.html(data);
        });

        $('.menuItem > a').on('click', function () {
            $('.active').removeClass('active')
            this.classList.toggle('active')
            let target = this.dataset.target;

            $.get("includes/" + target + ".html", function (data) {
                container.html(data);
            });

        });



        $('.submenuItem').on('click', function () {
            let target = this.dataset.target;
            $('.submenuItem').removeClass('active');
            $(this).addClass('active')
            $.get("includes/" + target + ".html", function (data) {
                container.html(data);
            });
        });

    })
}

const toggleNav = el => { 
    document.querySelector(el).classList.toggle('active')
}