const navigationObject = [{
        main: 'Allgemeines',
        sub: [
            'Arbeitszeiten',
            'Urlaub',
            'Krankmeldungen',
            'Verspätungen',
            'Material',
            'Getränke',
            'Soziales',
            'Kommen und Gehen',
            'Zuständigkeiten',
            'Kalender'
        ]
    },
    {
        main: 'Kontakte'
    },
    {
        main: 'Arbeitsabläufe',
        sub: [
            'Serverstruktur',
            'Ordnerstruktur',
            'E-Mail Ablage',
            'Workflow',
            'Datensicherheit',
            'Software'
        ]
    },
    {
        main: 'Know-how',
        sub: [
            'GitHub',
            'Sicherheit',
            'Technik',
            'HTML5 Banner',
            'Websites',
            'Wordpress',
            'Google Analytics',
            'SEO',
            'Datenschutzbestimmungen',
            'Lokaler Webserver',
            'Serverinfos',

        ]
    }
]