const backendSearchFunction = (id, group, event) => {
	
    const searchfield = document.getElementById(id)
    const searchfieldValue = searchfield.value.toLowerCase()
    const elementsGroups = document.querySelectorAll(group)
    // const elementsToSearch = document.querySelectorAll(`${group} h2, ${group} h3, ${group} input`)
	console.log(searchfieldValue)
    elementsGroups.forEach(elementGroup => { 
        let hasMatch = false
        const elementsToSearch = elementGroup.querySelectorAll('h2, h3, input')
        elementsToSearch.forEach(element => { 
            const tagType = element.tagName
            if (tagType === 'INPUT') {
                if(element.value.toLowerCase().indexOf(searchfieldValue) > -1) hasMatch = true
            } else { 
                if(element.textContent.toLowerCase().indexOf(searchfieldValue) > -1) hasMatch = true
            }
        })
        hasMatch ? elementGroup.style.display = '' : elementGroup.style.display = 'none'
    })
}

const searchFunction = (id, group, event) => {
	
    const searchfield = document.getElementById(id)
    const searchfieldValue = searchfield.value.toLowerCase()
    const elementsGroups = document.querySelectorAll(group)
    // const elementsToSearch = document.querySelectorAll(`${group} h2, ${group} h3, ${group} input`)
    elementsGroups.forEach(elementGroup => { 
        let hasMatch = false
        const elementsToSearch = elementGroup.querySelectorAll('h2, h3, input')
        elementsToSearch.forEach(element => { 
            const tagType = element.tagName
            if (tagType === 'INPUT') {
                if(element.value.toLowerCase().indexOf(searchfieldValue) > -1) hasMatch = true
            } else { 
                if(element.textContent.toLowerCase().indexOf(searchfieldValue) > -1) hasMatch = true
            }
        })
        hasMatch ? elementGroup.style.display = '' : elementGroup.style.display = 'none'
    })
}
if (document.querySelector('#adminsearch')) { 
    const adminSearch = document.querySelector('#adminsearch')
    adminSearch.addEventListener('keyup', e => searchFunction('adminsearch', '.client-group'))
    adminSearch.addEventListener('keyup', e => searchFunction('adminsearch', '.entry--group'))
    
}
if (document.querySelector('#backendsearch')) { 
    const backendSearch = document.querySelector('#backendsearch')
    backendSearch.addEventListener('keyup', e => backendSearchFunction('backendsearch', '.client-group'))
}